import "~/styles/global.css"
import React from "react"
import { AppService, AppProvider } from "~/services/app.service"
import { NextUIProvider } from "@nextui-org/react"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import settings from '~/settings'


export const appService = new AppService(settings)

const AppRoot: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  return (
    <AppProvider value={appService}>
      <button id="install" hidden>Install</button>
      <NextUIProvider>
        <NextThemesProvider attribute="class" defaultTheme="dark">
          {element}
        </NextThemesProvider>
      </NextUIProvider>
    </AppProvider>
  )
}

export default AppRoot
