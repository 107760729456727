import {
  auditTime,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  share
} from "rxjs"
import isBrowser from "~/utils/is-browser"
import { createProviderWithInit } from "~/utils/provider-factory"
import type { AppSettings } from "~/types"
import settings from "~/settings"
import RangeSubject from "~/utils/RangeSubject"
import chroma from "chroma-js"
import { ValueObservable } from "~/utils/ValueSubject"

type AppEnv = typeof process.env

export class AppService {
  
  public pageWidth$ = new BehaviorSubject<number>(isBrowser() ? window.innerWidth : 720)
  public pageHeight$ = new BehaviorSubject<number>(isBrowser() ? window.innerHeight : 720)


  public init() {
    if (!isBrowser()) return
    this.pageWidth$.next(window.innerWidth)
    this.pageHeight$.next(window.innerHeight)
  }

  constructor(
    public settings: AppSettings,
    public env: AppEnv = process.env
  ) {
    if (!isBrowser()) return

    const windowResize$ = fromEvent(window, "resize").pipe(auditTime(10), share())

    windowResize$
      .pipe(
        map(ev => (ev.target as Window).innerWidth),
        distinctUntilChanged()
      )
      .subscribe(this.pageWidth$)

    windowResize$
      .pipe(
        map(ev => (ev.target as Window).innerHeight),
        distinctUntilChanged()
      )
      .subscribe(this.pageHeight$)
  }
}

const tuple = createProviderWithInit<AppService>((app: AppService) => {
  return () => {
    app.init()
  }
})

export const AppProvider = tuple[0]
export const useApp = tuple[1]

export default AppProvider
