export function parseSettingsWithExt(s: {[key: string]: any}, parseExt: boolean = true) {
  let res: {[key: string]: any} = {}
  function rec(r: {[key: string]: any}, k: string[], v: any) {
    if (k.length == 1) {
      r[k[0]] = v
      return r
    } else {
      if (!r[k[0]]) r[k[0]] = {}
      r[k[0]] = rec(r[k[0]], k.slice(1), v)
      return r
    }
  }
  const lkeys = Object.keys(s)
  for (const lkey of lkeys) {
    const m = /^\[(.+)\]$/.exec(lkey)
    if (parseExt && m) {
      if (!res.ext) res.ext = {}
      res.ext[m[1]] = parseSettingsWithExt(s[lkey], false)
    } else {
      res = rec(res, lkey.split('.'), s[lkey])
    }
  }
  return res
}

export function parseSettingsPartial(s: { [key: string]: any }, ext: string = "") {
  let res: { [key: string]: any } = {}
  function rec(r: { [key: string]: any }, k: string[], v: any) {
    if (k.length == 1) {
      r[k[0]] = v
      return r
    } else {
      if (!r[k[0]]) r[k[0]] = {}
      r[k[0]] = rec(r[k[0]], k.slice(1), v)
      return r
    }
  }
  const lkeys = Object.keys(s)
  for (const lkey of lkeys) {
    if (ext) {
      const m = /^\[(.+)\]$/.exec(lkey)
      if (m && m[1] && m[1] === ext) {
        return parseSettingsPartial(s[lkey])
      }
    } else {
      res = rec(res, lkey.split("."), s[lkey])
    }
  }
  return res
}